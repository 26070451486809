import {
  VideoConferenceInfo,
  VideoConferenceRequestBody,
} from '@myhealthhub/video-conference-app-lib';
import axios from 'axios';

const url = `${process.env.VIDEO_CONFERENCE_BACKEND}/videoconference`;

export async function getVideoConference(
  from: string,
  roomCode: string,
  recaptchaAnswer: string,
): Promise<VideoConferenceInfo | null> {
  const reqBody: VideoConferenceRequestBody = {
    from,
    roomCode,
    'g-recaptcha-response': recaptchaAnswer,
  };

  const res = await axios.post<VideoConferenceInfo>(url, reqBody, {
    validateStatus: status => status === 200 || status === 404,
  });

  return res.data;
}
