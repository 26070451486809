import React, { ReactElement } from 'react';

export interface TransLinkProps {
  href: string;
  defaultText: string;
  testId?: string;
}

export function TransLink(props: TransLinkProps): ReactElement {
  return (
    <a
      href={props.href}
      data-testid={props.testId}
    >
      {props.defaultText}
    </a>
  );
}
