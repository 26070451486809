import { Collapse } from '@myhealthhub/design-system';
import React from 'react';
import { Trans } from 'react-i18next';
import '../../i18n';
import { LocationId } from '../directory/directory';

// Find FAQ content here:
// https://app.gitbook.com/@healthhub-1/s/design/ux-design/video-call/documentation
/* eslint new-cap: ["error", { "capIsNewExceptions": ["LocationId"] }] */

const helpCentreFaqs: Parameters<typeof Collapse>[0]['sections'] = [
  {
    id: 'faq-family-start',
    title: <Trans i18nKey="HELP_CENTRE_FAQS.HOW_DO_I_START_A_CALL.TITLE" />,
    content: (
      <>
        <p className="small">
          <Trans
            i18nKey="HELP_CENTRE_FAQS.HOW_DO_I_START_A_CALL.CONTENT_1"
            components={{ locationId: LocationId() }}
          />
        </p>
        <p className="small">
          <Trans
            i18nKey="HELP_CENTRE_FAQS.HOW_DO_I_START_A_CALL.CONTENT_2"
            components={{ locationId: LocationId() }}
          />
        </p>
      </>
    ),
  },
  {
    id: 'faq-patient-start',
    title: <Trans i18nKey="HELP_CENTRE_FAQS.CAN_A_PATIENT_START_A_CALL.TITLE" />,
    content: (
      <p className="small">
        <Trans i18nKey="HELP_CENTRE_FAQS.CAN_A_PATIENT_START_A_CALL.CONTENT" />
      </p>
    ),
  },
  {
    id: 'faq-group-call',
    title: <Trans i18nKey="HELP_CENTRE_FAQS.CAN_I_MAKE_A_GROUP_CALL.TITLE" />,
    content: (
      <p className="small">
        <Trans i18nKey="HELP_CENTRE_FAQS.CAN_I_MAKE_A_GROUP_CALL.CONTENT" />
      </p>
    ),
  },
  {
    id: 'faq-service-free',
    title: <Trans i18nKey="HELP_CENTRE_FAQS.IS_THE_SERVICE_FREE.TITLE" />,
    content: (
      <p className="small">
        <Trans i18nKey="HELP_CENTRE_FAQS.IS_THE_SERVICE_FREE.CONTENT" />
      </p>
    ),
  },
  {
    id: 'faq-browser-support',
    title: <Trans i18nKey="HELP_CENTRE_FAQS.WHAT_BROWSERS_ARE_SUPPORTED.TITLE" />,
    content: (
      <>
        <p className="small">
          <Trans i18nKey="HELP_CENTRE_FAQS.WHAT_BROWSERS_ARE_SUPPORTED.CONTENT_1" />
        </p>
        <p className="small">
          <Trans i18nKey="HELP_CENTRE_FAQS.WHAT_BROWSERS_ARE_SUPPORTED.CONTENT_2" />
        </p>
        <p className="small"><Trans i18nKey="HELP_CENTRE_FAQS.WHAT_BROWSERS_ARE_SUPPORTED.CONTENT_3" /></p>
      </>
    ),
  },
  {
    id: 'faq-protecting-privacy',
    title: <Trans i18nKey="HELP_CENTRE_FAQS.HOW_ARE_WE_PROTECTING_PARTICIPANTS_PRIVACY.TITLE" />,
    content: (
      <>
        <p className="small">
          <Trans i18nKey="HELP_CENTRE_FAQS.HOW_ARE_WE_PROTECTING_PARTICIPANTS_PRIVACY.CONTENT_1" />
        </p>
        <p className="small">
          <Trans i18nKey="HELP_CENTRE_FAQS.HOW_ARE_WE_PROTECTING_PARTICIPANTS_PRIVACY.CONTENT_2" />
        </p>
      </>
    ),
  },
];

export default helpCentreFaqs;
