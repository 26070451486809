import { Icon, MainContent, palette } from '@myhealthhub/design-system';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useFocusOnRouteChange } from '../../hooks';
import '../../i18n';
import { ViewBase } from '../viewBase';
import './callEnd.css';

function CallEnd(props: RouteComponentProps): ReactElement {
  const translate = useTranslation().t;
  useFocusOnRouteChange(props.history.action);

  return (
    <ViewBase view="call">
      <div className="end-content flex center text-center neutral-white page-height tertiary">
        <MainContent className="end-main">
          <div aria-hidden="true" className="pad-xlarge-top" />
          <div>
            <Icon name="EndCall" label="" size="4.5rem" colour={palette.tertiary.main} />
            <h1 className="titleSmall">{translate('CALL_ENDED')}</h1>
          </div>
          <div className="pad-xlarge-top action">
            <Link
              to={ROUTES.HOME}
              className="button outline white button-small"
            >
              {translate('CLOSE')}
            </Link>
          </div>
        </MainContent>
      </div>
    </ViewBase>
  );
}

export default withRouter(CallEnd);
