/* eslint max-lines: off */
import { Button, Fieldset, Icon, MainContent, palette, SelectField, SimpleModal } from '@myhealthhub/design-system';
import { TFunction } from 'i18next';
import { ReactComponentLike } from 'prop-types';
import React, { FormEvent, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { isIOS, osVersion } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';
import { ROUTES, VIDEO } from '../../constants';
import { VideoPropertyContext } from '../../context/VideoPropertyContext';
import { useFocusOnRouteChange, useLocalStorage } from '../../hooks';
import '../../i18n';
import { ViewBase } from '../viewBase';
import './devicePreview.css';

interface AudioElement extends HTMLMediaElement {
  play: () => Promise<void>;
  setSinkId?: (sinkId: string) => Promise<undefined>;
}

function getDeviceOptions(devices: MediaDevice[], translate: TFunction): Record<string, string>[] {
  if (devices.length === 0) {
    return [{ value: '', name: translate('PERMISSIONS_NOT_GRANTED') }];
  }

  return devices.map(({ deviceId, label }) => ({
    value: deviceId,
    name: label,
  }));
}

const audio: AudioElement = new Audio('./assets/ring.wav');
const isSetSinkIdSupported = () => typeof audio.setSinkId === 'function';

function DevicePreview(props: DevicePreviewProps): ReactElement {
  const history = useHistory();

  const { state: videoProperties, update: updateVideoProperties } = useContext(VideoPropertyContext);
  const [videoConstraints, setVideoConstraints] = useState<MediaStreamConstraints['video']>(true);

  const [mediaDevices, setMediaDevices] = useState<MediaDevices>({audioInputs: [], audioOutputs: [], videoInputs: []});
  const [audioInputSelected, setAudioInputSelected] = useState(videoProperties.devices.audioInput ?? '');
  const [audioOutputSelected, setAudioOutputSelected] = useState(videoProperties.devices.audioOutput ?? '');
  const [videoInputSelected, setVideoInputSelected] = useState(videoProperties.devices.videoInput ?? '');

  const [enableVideo, setEnableVideo] = useState(videoProperties.enableVideo);
  const [enableAudio, setEnableAudio] = useState(videoProperties.enableAudio);

  const WebcamComponent = props.webcamComponent ?? Webcam;

  // Workaround for false positives: https://github.com/duskload/react-device-detect/issues/102
  const isIos13 = isIOS && osVersion.split('.')[0] === '13';

  const audioStart = useCallback(() => {
    if (isSetSinkIdSupported()) {
      audio.setSinkId(audioOutputSelected).catch(console.error);
    } else {
      console.debug(`setSinkId is not supported in this browser`);
    }
    audio.play().catch(console.error);
  }, [audioOutputSelected]);

  useEffect(() => {
    if (videoInputSelected) {
      setVideoConstraints({
        deviceId: {
          exact: videoInputSelected,
        },
      });
    } else {
      setVideoConstraints(true);
    }
  }, [videoInputSelected]);

  const updateDeviceList = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const devicesByKind = groupDevicesByKind(devices);

      updateSelectedDevice(devicesByKind.audioInputs, audioInputSelected, setAudioInputSelected);
      updateSelectedDevice(devicesByKind.audioOutputs, audioOutputSelected, setAudioOutputSelected);
      updateSelectedDevice(devicesByKind.videoInputs, videoInputSelected, setVideoInputSelected);

      setMediaDevices(devicesByKind);
    }).catch(err => {
      console.error(err);
      history.push(ROUTES.ERROR);
    });
  }, [audioInputSelected, audioOutputSelected, history, videoInputSelected]);

  useEffect(() => {
    if (typeof navigator.mediaDevices?.addEventListener === 'function') {
      navigator.mediaDevices.addEventListener('devicechange', updateDeviceList);
      return () => navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList);
    }
  }, [updateDeviceList]);

  useLocalStorage('previewEnableVideo', enableVideo);
  useLocalStorage('previewEnableAudio', enableAudio);
  useLocalStorage('previewAudioOutput', audioOutputSelected);
  useLocalStorage('previewAudioInput', audioInputSelected);
  useLocalStorage('previewVideoInput', videoInputSelected);

  const onCancelSettings = useCallback(() => {
    history.push(ROUTES.HOME);
  }, [history]);

  const onSubmitSettings = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateVideoProperties({
      ...videoProperties,
      devices: {
        videoInput: videoInputSelected,
        audioInput: audioInputSelected,
        audioOutput: audioOutputSelected,
      },
      enableAudio,
      enableVideo,
    });

    history.push(ROUTES.CALL);
  }, [
    audioInputSelected,
    audioOutputSelected,
    enableAudio,
    enableVideo,
    history,
    updateVideoProperties,
    videoProperties,
    videoInputSelected,
  ]);

  const translate = useTranslation().t;
  const legend = (
    <span className="neutral-dark uppercase bold small margin-small-bottom">
      {translate('CALL_PREFERENCES')}
    </span>
  );

  const audioOutputSettings = (
    <SelectField
      onChange={inputHandler(setAudioOutputSelected)}
      value={audioOutputSelected}
      options={getDeviceOptions(mediaDevices.audioOutputs, translate)}
      theme="tertiary"
      label={translate('AUDIO_OUTPUT')}
      required
    />
  );

  const audioOutputFallback = (
    <div className="audio-output">
      <strong className="label-text xsmall">{translate('AUDIO_OUTPUT')}</strong>
      <p className="xsmall">
        {translate('DEFAULT_AUDIO_DISCLAIMER')}
      </p>
    </div>
  );

  useEffect(() => {
    if (!videoProperties.jwt) {
      history.push(ROUTES.HOME);
    }
  }, [history, videoProperties.jwt]);

  useEffect(() => {
    function onbeforeunload() {
      updateVideoProperties({
        ...videoProperties,
        roomName: null,
        generatedRoomName: null,
        jwt: null,
      });
    }

    window.addEventListener('beforeunload', onbeforeunload);
    return () => window.removeEventListener('beforeunload', onbeforeunload);
  }, [updateVideoProperties, videoProperties]);

  // workaround for https://gitlab.hndev.ca/myhealthhub/design-system/-/issues/111
  useEffect(() => () => {
    document.body.style.top = '';
    document.body.style.position = '';
  }, []);

  useFocusOnRouteChange(history.action);

  return (
    <ViewBase view="family" className="device-preview">
      <SimpleModal doClose={onCancelSettings} large active>
        <MainContent className="preview-main">
          <h1 className="tertiary-dark title">{translate('CALL_PREVIEW')}</h1>
          <form
            className="margin-medium-top preview-form"
            onSubmit={onSubmitSettings}
          >
            <div className="flex top">
              <div className="settings">
                <Fieldset legend={legend}>
                  <SelectField
                    onChange={inputHandler(setVideoInputSelected)}
                    value={videoInputSelected}
                    options={getDeviceOptions(mediaDevices.videoInputs, translate)}
                    theme="tertiary"
                    label={translate('CAMERA')}
                    required
                  />
                  <SelectField
                    onChange={inputHandler(setAudioInputSelected)}
                    value={audioInputSelected}
                    options={getDeviceOptions(mediaDevices.audioInputs, translate)}
                    theme="tertiary"
                    label={translate('MICROPHONE')}
                    required
                  />
                  <>
                    {isSetSinkIdSupported()
                    && (mediaDevices.audioOutputs.length > 0)
                      ? audioOutputSettings
                      : audioOutputFallback}
                    <Button
                      onClick={audioStart}
                      styling="link"
                      theme="tertiary"
                      size="small"
                    >
                      {translate('PLAY_A_TEST_SOUND')}
                    </Button>
                  </>
                </Fieldset>
                <div className="margin-large-top margin-large-bottom form-submit">
                  <Button
                    theme="tertiary"
                    styling={isIos13 ? 'link' : 'outline'}
                    size="small"
                    onClick={onCancelSettings}
                  >
                    {translate('CANCEL')}
                  </Button>
                  <Button
                    theme="tertiary"
                    styling={isIos13 ? 'outline' : 'solid'}
                    type="submit"
                    size="small"
                  >
                    {translate('JOIN_NOW')}
                  </Button>
                </div>
              </div>
              <div className="preview margin-xlarge-left margin-medium-top">
                <div className="video-container margin-small-bottom">
                  <div
                    className="no-video bg-neutral-dark flex"
                    aria-hidden="true"
                  >
                    <span className="avatar bg-neutral-offwhite">
                      <span className="head bg-neutral-light margin-center" />
                      <span className="body bg-neutral-light margin-center" />
                    </span>
                  </div>
                  <WebcamComponent
                    width={VIDEO.WIDTH}
                    height={VIDEO.HEIGHT}
                    mirrored
                    videoConstraints={videoConstraints}
                    onUserMedia={updateDeviceList}
                  />
                </div>
                <div className="actions">
                  <Button
                    size="small"
                    disabled={!videoInputSelected}
                    onClick={toggleHandler(setEnableVideo, enableVideo)}
                    theme="neutral"
                  >
                    <Icon
                      name={enableVideo
                        ? 'Camera'
                        : 'DisableCamera'}
                      colour={enableVideo
                        ? palette.neutral.black
                        : palette.error.dark}
                      size="2em"
                      label=""
                    />
                    <span
                      className={`margin-xsmall-left ${
                        enableVideo
                          ? 'neutral-black'
                          : 'error-dark'
                      }`}
                    >
                      {enableVideo ? translate('TURN_CAMERA_OFF') : translate('TURN_CAMERA_ON')}
                    </span>
                  </Button>
                  <Button
                    size="small"
                    disabled={!audioInputSelected}
                    onClick={toggleHandler(setEnableAudio, enableAudio)}
                    theme="neutral"
                  >
                    <Icon
                      name={enableAudio
                        ? 'Microphone'
                        : 'MicrophoneMute'}
                      colour={enableAudio
                        ? palette.neutral.black
                        : palette.error.dark}
                      size="2em"
                      label=""
                    />
                    <span
                      className={`margin-xsmall-left ${
                        enableAudio
                          ? 'neutral-black'
                          : 'error-dark'
                      }`}
                    >
                      {enableAudio ? translate('TURN_MICROPHONE_OFF') : translate('TURN_MICROPHONE_ON')}
                    </span>
                  </Button>
                  <div role="status" className="visually-hidden">
                    {enableVideo ? translate('CAMERA_ON') : translate('CAMERA_OFF')}
                    {enableAudio ? translate('MICROPHONE_ON') : translate('MICROPHONE_OFF')}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </MainContent>
      </SimpleModal>
    </ViewBase>
  );
}

export default withRouter(DevicePreview);

interface DevicePreviewProps extends RouteComponentProps {
  webcamComponent?: ReactComponentLike;
}

function groupDevicesByKind(devices: MediaDevice[]): MediaDevices {
  const ofKind = (devices: MediaDevice[], kind: string) => devices.filter(
    device => device.kind === kind && device.label !== '',
  );
  return {
    audioInputs: ofKind(devices, 'audioinput'),
    audioOutputs: ofKind(devices, 'audiooutput'),
    videoInputs: ofKind(devices, 'videoinput'),
  };
}
function deviceIdExists(devices: MediaDevice[], deviceId: string) {
  return !!devices.find(
    device => device.deviceId === deviceId,
  );
}
function updateSelectedDevice(
  devices: MediaDevice[],
  selectedDevice: string,
  deviceSetter: (value: string) => void,
) {
  if (devices.length && !deviceIdExists(devices, selectedDevice)) {
    deviceSetter(devices[0].deviceId);
  }
}

interface MediaDevices {
  audioInputs: MediaDevice[];
  audioOutputs: MediaDevice[];
  videoInputs: MediaDevice[];
}

interface MediaDevice {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
}

function inputHandler(setter: (value: string) => void) {
  return (event: FormEvent<HTMLInputElement>) => setter(event.currentTarget.value);
}

function toggleHandler(setter: (value: boolean) => void, value: boolean) {
  return () => setter(!value);
}
