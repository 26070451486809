import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';

function Privacy(): ReactElement {
  const translate = useTranslation().t;

  return <h1>{translate('PRIVACY')}</h1>;
}

export default Privacy;
