import React, { ReactElement } from 'react';
import './PageHeading.css';

interface PageHeadingProps {
  main: string;
  sub?: string;
}

function PageHeading(props: PageHeadingProps): ReactElement {
  const { main, sub } = props;

  return (
    <h1 className="page-heading tertiary-dark title-small" {...props}>
      <span>
        {main}
        {' '}
      </span>
      {sub && <small className="normal">{sub}</small>}
    </h1>
  );
}

export default PageHeading;
