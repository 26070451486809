import { Collapse, Icon, MainContent, palette } from '@myhealthhub/design-system';
import React, { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { PageHeading } from '../../components';
import { ROUTES, SUPPORT_NUMBER } from '../../constants';
import { useFocusOnRouteChange } from '../../hooks';
import '../../i18n';
import { ViewBase } from '../viewBase';
import './helpCentre.css';
import helpCentreFaqs from './helpCentreFaqs';

function HelpCentre(props: RouteComponentProps): ReactElement {
  const [faqs, setFaqs] = useState(helpCentreFaqs);
  const history = useHistory();
  const { location } = props;
  const translate = useTranslation().t;

  const onOpenFaq = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;

    if (button.getAttribute('aria-expanded') === 'false') {
      const faqId = button.getAttribute('aria-controls').replace('_content', '');

      history.push(`${ROUTES.HELP}#${faqId}`);
    }
  }, [history]);

  useEffect(() => {
    if (location.hash) {
      const openFaqs = helpCentreFaqs.map(
        faq => ({
          ...faq,
          defaultOpen: `#${faq.id}` === location.hash,
        }),
      );
      setFaqs(openFaqs);

      document.getElementById(location.hash)?.scrollTo();
    }
  }, [location.hash]);

  useFocusOnRouteChange(props.history.action, location.hash);

  return (
    <ViewBase view="family" className="directory help-centre">
      <MainContent className="main pad-xlarge-top margin-center">
        <div className="content bg-neutral-offwhite round-medium pad-large tertiary">
          <PageHeading
            main={translate('HELP_CENTRE')}
            sub={translate('FIND_ANSWERS_TO_YOUR_QUESTIONS_BELOW')}
            data-testid="helpCentreHeading"
          />
          <div className="support bg-neutral-white flex space-between round-medium pad-small">
            <small className="pad-medium-right">
              {translate('HELP_CENTRE_CONTACT_US')}
            </small>
            <span className="bg-neutral-light round-medium flex right pad-xsmall-y pad-small-x">
              <Icon name="PhoneAnswer" size="1.5em" colour={palette.neutral.black} label="" />
              <strong className="margin-xsmall-left">
                {SUPPORT_NUMBER}
              </strong>
            </span>
          </div>
          <Collapse
            theme="tertiary"
            sections={faqs}
            onOpen={onOpenFaq}
            className="margin-medium-y"
            data-testid="helpCentreFaqs"
          />
        </div>
      </MainContent>
    </ViewBase>
  );
}

export default withRouter(HelpCentre);
