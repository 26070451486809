import {
  Button,
  Icon,
  MainContent,
  manageFallbackFocus,
  palette,
} from '@myhealthhub/design-system';
import { Command, ConfigOptions, Events } from '@myhealthhub/video-conference-app-lib';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import 'regenerator-runtime/runtime.js';
import { VideoDisplay } from '../../components/VideoDisplay';
import { ROUTES } from '../../constants';
import { VideoPropertyContext } from '../../context/VideoPropertyContext';
import { useFocusOnRouteChange } from '../../hooks';
import '../../i18n';
import { ViewBase } from '../viewBase';
import './conferenceRoom.css';

function ConferenceRoom(props: RouteComponentProps): ReactElement {
  const history = useHistory();
  const { state: videoProperties, update: updateVideoProperties } = useContext(VideoPropertyContext);
  const [enableVideo, setEnableVideo] = useState(videoProperties.enableVideo);
  const [enableAudio, setEnableAudio] = useState(videoProperties.enableAudio);
  const [enableTileView, setEnableTileView] = useState(true);
  const [showOptions, setShowOptions] = useState(true);
  const translate = useTranslation().t;

  function toggleActionHandler(command: string) {
    return () => {
      videoProperties.api.executeCommand(Command[command]);
      manageFallbackFocus();
    };
  }

  function toggleShowOptions() {
    setShowOptions(!showOptions);
  }

  const cleanup = useCallback(() => {
    if (videoProperties.api) {
      videoProperties.api.dispose();
    }

    updateVideoProperties({
      ...videoProperties,
      jwt: null,
      api: null,
      roomName: null,
      generatedRoomName: null,
      devices: {
        ...videoProperties.devices,
        videoInput: null,
      },
    });
  }, [updateVideoProperties, videoProperties]);

  const hangup = useCallback(() => {
    if (videoProperties.api) {
      videoProperties.api.executeCommand(Command.hangup);
    }

    cleanup();

    history.push(ROUTES.END);
  }, [videoProperties.api, cleanup, history]);

  useEffect(() => {
    function onAudioMuteStatusChanged(args: { muted: boolean }): void {
      setEnableAudio(!args.muted);
    }

    function onVideoMuteStatusChanged(args: { muted: boolean }): void {
      setEnableVideo(!args.muted);
    }

    function onTileViewChanged(args: { enabled: boolean }): void {
      setEnableTileView(args.enabled);
    }

    function onParticipantLeft(): void {
      if (videoProperties.api.getNumberOfParticipants() < 2) {
        hangup();
      }
    }

    if (videoProperties.api) {
      videoProperties.api.on(Events.audioMute, onAudioMuteStatusChanged);
      videoProperties.api.on(Events.videoMute, onVideoMuteStatusChanged);
      videoProperties.api.on(Events.tileView, onTileViewChanged);
      videoProperties.api.on(Events.participantLeft, onParticipantLeft);
    }
  }, [videoProperties.api, hangup]);

  useEffect(() => {
    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, [cleanup]);

  useEffect(() => {
    const checkWindowSize = () => {
      // Hide options panel by default on smaller screens
      if (window.innerWidth <= 680) {
        setShowOptions(false);
      }
    };

    window.addEventListener('resize', checkWindowSize);
    checkWindowSize();

    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  useEffect(() => {
    const { classList } = document.querySelector('html');
    classList.add('support-fullscreen');
    return () => classList.remove('support-fullscreen');
  }, []);

  const config: ConfigOptions = {
    startWithAudioMuted: !enableAudio,
    startWithVideoMuted: !enableVideo,
    disableDeepLinking: true,
    prejoinPageEnabled: false,
  };

  useFocusOnRouteChange(props.history.action);

  return (
    <ViewBase view="call">
      <MainContent className="call-content flex page-height page-width">
        <h1 className="visually-hidden">{translate('VIDEO_CALL')}</h1>
        <div className="video-container page-width">
          <VideoDisplay
            Config={config}
            jwt={videoProperties.jwt}
            generatedRoomName={videoProperties.generatedRoomName}
          />
        </div>
        <nav className="video-controls page-width bg-neutral-offwhite flex space-between">
          <div className="settings pad-medium-x">
            <div className="options-toggle">
              <Button
                size="small"
                styling="link"
                theme="tertiary"
                className="neutral-black"
                aria-controls="video-settings-options"
                aria-expanded={showOptions}
                onClick={toggleShowOptions}
              >
                <Icon
                  name="ThreeDots"
                  colour={palette.neutral.black}
                  size="2.25em"
                  label=""
                />
                <span className="visually-hidden">{translate('OPTIONS')}</span>
              </Button>
            </div>
            <div
              id="video-settings-options"
              className="settings-options bg-neutral-offwhite round-medium"
              aria-hidden={!showOptions}
            >
              <Button
                size="small"
                styling="link"
                theme="tertiary"
                className="neutral-black"
                onClick={toggleActionHandler('toggleTileView')}
              >
                <Icon
                  name={enableTileView
                    ? 'SquareInSquare'
                    : 'FourSquares'}
                  colour={palette.neutral.black}
                  size="2.25em"
                  label=""
                />
                <span className="margin-xsmall-left">
                  {enableTileView
                    ? translate('VIEW_FULL_SCREEN')
                    : translate('VIEW_TILE')}
                </span>
              </Button>
            </div>
          </div>
          <div className="devices pad-medium-x flex">
            <Button
              size="small"
              className="neutral bg-neutral-light"
              onClick={toggleActionHandler('toggleVideo')}
              theme="neutral"
            >
              <Icon
                name={enableVideo ? 'Camera' : 'DisableCamera'}
                colour={enableVideo
                  ? palette.neutral.black
                  : palette.error.dark}
                size="2.25em"
                label=""
              />
              <span className="visually-hidden">
                {enableVideo
                  ? translate('TURN_CAMERA_OFF')
                  : translate('TURN_CAMERA_ON')}
              </span>
            </Button>
            <Button
              size="small"
              className="neutral bg-neutral-light"
              onClick={toggleActionHandler('toggleAudio')}
              theme="neutral"
            >
              <Icon
                name={enableAudio
                  ? 'Microphone'
                  : 'MicrophoneMute'}
                colour={enableAudio
                  ? palette.neutral.black
                  : palette.error.dark}
                size="2.25em"
                label=""
              />
              <span className="visually-hidden">
                {enableAudio
                  ? translate('TURN_MICROPHONE_OFF')
                  : translate('TURN_MICROPHONE_ON')}
              </span>
            </Button>
            <Button
              size="small"
              theme="error"
              onClick={hangup}
            >
              <Icon
                name="EndCall"
                colour={palette.neutral.white}
                size="2.25em"
                label=""
              />
              <span className="visually-hidden">{translate('END_CALL')}</span>
            </Button>
          </div>
          <div className="status">
            <div className="visually-hidden" role="status">
              {enableVideo ? translate('CAMERA_ON') : translate('CAMERA_OFF')}
              {enableAudio ? translate('MICROPHONE_ON') : translate('MICROPHONE_OFF')}
              {enableTileView ? translate('VIEW_TILE_ON') : translate('VIEW_FULL_SCREEN_ON')}
            </div>
          </div>
        </nav>
      </MainContent>
    </ViewBase>
  );
}

export default withRouter(ConferenceRoom);
