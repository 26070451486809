import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';

function Terms(): ReactElement {
  const translate = useTranslation().t;

  return <h1>{translate('TERMS')}</h1>;
}

export default Terms;
