import { Icon } from '@myhealthhub/design-system';
import React, { ReactElement } from 'react';

function LinearGradient(
  props: { id: string; x1: string; x2: string; y1: string; y2: string },
): ReactElement {
  return (
    <linearGradient {...props}>
      <stop offset="0%" stopColor="#007065" />
      <stop offset="100%" stopColor="#009385" />
    </linearGradient>
  );
}

function Path(
  props: { stroke: string; d: string },
): ReactElement {
  return (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      {...props}
    />
  );
}
function ErrorIcon(): ReactElement {
  return (
    <Icon label="">
      <svg width="450" height="170" viewBox="0 0 450 170">
        <defs>
          <LinearGradient id="error-icon-b" x1="-13.311%" x2="122.276%" y1="140.44%" y2="-32.103%" />
          <LinearGradient id="error-icon-c" x1="50%" x2="50%" y1="230.339%" y2="-561.532%" />
          <LinearGradient id="error-icon-e" x1="100%" x2="10.922%" y1="132.611%" y2="-53.181%" />
          <filter id="error-icon-a" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(-1 -17)">
          <path d="M0 17H450V187H0z" />
          <ellipse
            cx="226.5"
            cy="103"
            fill="#D8E9E8"
            filter="url(#error-icon-a)"
            opacity=".3"
            rx="59.5"
            ry="114"
            transform="rotate(115 226.5 103)"
          />
          <Path stroke="#007065" d="M157 142L151 148" />
          <Path stroke="#009385" d="M290.591 36L282.106 50.697" />
          <Path stroke="#007065" d="M151 142L157 148" />
          <Path stroke="#009385" d="M279 39.106L293.697 47.591" />
          <g transform="translate(147 36)">
            <path
              fill="#F7F7F7"
              stroke="url(#error-icon-b)"
              strokeLinecap="round"
              strokeWidth="6"
              d={`M40.504 48.614l77.89-21.018c5.332-1.439 10.821 1.717 12.26 7.05.46 1.706.46 3.504 0 5.21l-21.018 \
              77.89c-1.439 5.332-6.928 8.489-12.26 7.05-1.689-.456-3.229-1.347-4.466-2.584L36.038 \
              65.34c-3.905-3.905-3.905-10.237 0-14.142 1.237-1.237 2.777-2.128 4.466-2.584z`}
              transform="rotate(-45 79.5 78.75)"
            />
            <path
              stroke="rgb(0, 112, 101)"
              opacity=".95"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="6"
              d="M80 65L80 37"
            />
            <circle cx="80" cy="81" r="6" fill="url(#error-icon-c)" />
          </g>
          <g fill="#FFF" strokeWidth="4">
            <g transform="translate(122 90) translate(165)">
              <path
                stroke="url(#error-icon-e)"
                strokeLinecap="round"
                strokeLinejoin="round"
                d={`M10.957 22.873l19.27 6.803c4.607 1.626 9.66-.79 11.286-5.397.12-.343.22-.693.299-1.049 \
                1.077-4.91-2.03-9.763-6.94-10.84-.281-.063-.565-.111-.851-.146l-7.646-.93h0l5.333-3.08c2.066-1.193 \
                2.846-3.788 1.782-5.923C32.46.25 29.956-.589 27.894.44l-.065.033-15.068 7.843h0`}
              />
              <rect
                width="8"
                height="20"
                x="4"
                y="4.065"
                stroke="#008276"
                rx="4"
                transform="rotate(7 8 14.065)"
              />
            </g>
            <g transform="translate(122 90) matrix(-1 0 0 1 43 0)">
              <path
                stroke="url(#error-icon-e)"
                strokeLinecap="round"
                strokeLinejoin="round"
                d={`M10.957 22.873l19.27 6.803c4.607 1.626 9.66-.79 11.286-5.397.12-.343.22-.693.299-1.049 \
                1.077-4.91-2.03-9.763-6.94-10.84-.281-.063-.565-.111-.851-.146l-7.646-.93h0l5.333-3.08c2.066-1.193 \
                2.846-3.788 1.782-5.923C32.46.25 29.956-.589 27.894.44l-.065.033-15.068 7.843h0`}
              />
              <rect
                width="8"
                height="20"
                x="4"
                y="4.065"
                stroke="#008276"
                rx="4"
                transform="rotate(7 8 14.065)"
              />
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
}

export default ErrorIcon;
