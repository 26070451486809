import { Icon, Toast } from '@myhealthhub/design-system';
import React, { ReactElement, useState } from 'react';
import { browserName, isAndroid, isIOS, isMobile, osVersion } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { ROUTES } from '../../constants';

export default function UnsupportedBrowserToast(): ReactElement {
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState(!isBrowserSupported());

  function dismissUnsupportedBrowser() {
    setShowUnsupportedBrowser(false);
  }

  return (
    <Toast
      active={showUnsupportedBrowser}
      doDismiss={dismissUnsupportedBrowser}
    >
      <div className="flex left top">
        <Icon
          name="BellOutline"
          className="icon-tertiary-dark"
          label=""
          size="2rem"
          data-testid="unsupportedBrowserIcon"
        />
        <p data-testid="unsupportedBrowserMessage">
          <Trans i18nKey="UNSUPPORTED_BROWSER_MESSAGE">
            Your browser may not be supported. Visit the
            <HashLink className=".primary a" to={`${ROUTES.HELP}#faq-browser-support`} smooth>
              help page
            </HashLink>
            for more information.
          </Trans>
        </p>
      </div>
    </Toast>
  );
}

function isBrowserSupported() {
  const osVersionNumber = parseInt(osVersion);

  if (isAndroid && osVersionNumber >= 10 || isIOS && osVersionNumber >= 14) {
    return true;
  } else if (!isMobile && ['Chrome', 'Firefox', 'Safari', 'Edge'].includes(browserName)) {
    return true;
  }

  return false;
}
