import { createContext } from '@myhealthhub/react-context-module';
import { Events } from '@myhealthhub/video-conference-app-lib';
import { isDisplayNameValid } from '../util/stringUtil';

export interface VideoContext {
  displayName?: string;
  roomName?: string;
  generatedRoomName?: string;
  api?: ApiObject;
  jwt?: string;
  devices?: {
    audioInput?: string;
    videoInput?: string;
    audioOutput?: string;
  };
  enableAudio?: boolean;
  enableVideo?: boolean;
}

interface ApiObject {
  on: (event: Events, listener: (args?: Record<string, boolean>) => void) => void;
  options?: JitsiMeetApiOptions;
  executeCommand(command: string, value?: string): void;
  getNumberOfParticipants(): number;
  dispose(): void;
}

const localDisplayName = localStorage.getItem('directoryDisplayName');

const initialContext: VideoContext = {
  displayName: localDisplayName && isDisplayNameValid(localDisplayName) ? localDisplayName : null,
  roomName: null,
  generatedRoomName: null,
  api: null,
  jwt: null,
  devices: {
    audioInput: localStorage.getItem('previewAudioInput'),
    audioOutput: localStorage.getItem('previewAudioOutput'),
    videoInput: localStorage.getItem('previewVideoInput'),
  },
  enableAudio: !!localStorage.getItem('previewEnableAudio'),
  enableVideo: !!localStorage.getItem('previewEnableVideo'),
};

export const [VideoPropertyContext, VideoPropertyProvider] = createContext(
  initialContext,
);
