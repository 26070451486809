import { Icon } from '@myhealthhub/design-system';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import '../../i18n';
import './Footer.css';
import { TransLink } from '../../util/linkUtil';

const year = new Date().getFullYear();

interface FooterLinkContentProps {
  children: ReactElement | string;
  external?: boolean;
}

export function FooterLinkContent(props: FooterLinkContentProps): ReactElement {
  const { children, external } = props;
  const translate = useTranslation().t;

  return (
    <>
      <Icon name="UpArrow" size="1.5rem" className="arrow" label="" />
      <span className="link-text normal">{children}</span>
      {external && (
        <>
          <span className="visually-hidden">
            {' '}
            {translate('OPENS_IN_NEW_TAB')}
          </span>
          <Icon name="ExternalLink" size="0.775rem" className="external" label="" scaleWidth />
        </>
      )}
    </>
  );
}

interface FooterLinkProps {
  to: string;
  children: ReactElement | string;
  external?: boolean;
  current?: boolean;
}

export function FooterLink(props: FooterLinkProps): ReactElement {
  const { to, children, external, current } = props;
  const linkColour = current
    ? 'tertiary-dark icon-tertiary-dark'
    : 'neutral-black icon-neutral-black';
  const linkContent = (
    <FooterLinkContent external={external}>
      {children}
    </FooterLinkContent>
  );
  const linkProps = {
    className: `flex left ${linkColour}`,
    children: linkContent,
  };

  return (
    <div className="footer-link">
      {external
        ? (
          <a href={to} target="_blank" rel="noreferrer noopener" {...linkProps}>
            {linkContent}
          </a>
        )
        : <Link to={to} {...linkProps} />}
    </div>
  );
}

function HealthHubLink(): ReactElement {
  const translate = useTranslation().t;

  return (
    <TransLink
      href={translate('EXT_HEALTHHUB')}
      defaultText={translate('HEALTHHUB_PATIENT_ENGAGEMENT_SOLUTIONS')}
      testId="hhFooterLink"
    />
  );
}

function Footer(props: RouteComponentProps): ReactElement {
  const isHelpCentre = props.location.pathname === ROUTES.HELP;
  const translate = useTranslation().t;
  const healthHubLink = <HealthHubLink />;

  return (
    <footer className="footer page-width bg-neutral-light text-center">
      <div className="pad-medium margin-auto">
        <nav className="flex">
          <FooterLink to={ROUTES.HELP} current={isHelpCentre}>
            {translate('HELP_CENTRE')}
          </FooterLink>
          <FooterLink to={translate('EXT_RESOURCES')} external>
            {translate('HEALTHHUB_RESOURCES')}
          </FooterLink>
        </nav>
        <p className="neutral-black xsmall margin-medium-top tertiary">
          <span>
            <Trans
              i18nKey="PROVIDED_BY_HEALTHHUB"
              components={{ transLink: healthHubLink }}
            />
          </span>
          {' '}
          <span>{translate('COPYRIGHT', {year})}</span>
        </p>
      </div>
    </footer>
  );
}

export default withRouter(Footer);
