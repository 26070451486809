export const ROUTES = {
  CALL: '/call',
  END: '/end',
  ERROR: '/error',
  HOME: '/',
  PREVIEW: '/preview',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  HELP: '/help',
};

export const ASSETS_PATH = '/assets';

export const VIDEO = {
  WIDTH: 640,
  HEIGHT: 360,
  RATIO: 0.5625,
};

const DEFAULTS = {
  CAPTCHA_DATA_SITE_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  HOSPITAL_NAME: process.env.NODE_ENV === 'production' ? '' : 'My HealthHub Hospital',
  HOSPITAL_SUPPORT_NUMBER: process.env.NODE_ENV === 'production' ? '' : '0000',
  JWT_APP_SECRET: '',
  VIDEO_ENGINE_ORIGIN: '',
};

export const CAPTCHA_DATA_SITE_KEY = process.env.CAPTCHA_DATA_SITE_KEY || DEFAULTS.CAPTCHA_DATA_SITE_KEY;
export const HOSPITAL_NAME = process.env.HOSPITAL_NAME || DEFAULTS.HOSPITAL_NAME;
export const SUPPORT_NUMBER = process.env.HOSPITAL_SUPPORT_NUMBER || DEFAULTS.HOSPITAL_SUPPORT_NUMBER;
export const JWT_APP_SECRET = process.env.JWT_APP_SECRET || DEFAULTS.JWT_APP_SECRET;
export const VIDEO_ENGINE_ORIGIN = (process.env.VIDEO_ENGINE_ORIGIN || DEFAULTS.VIDEO_ENGINE_ORIGIN).trim();
export const LOCATION_PARAM = 'loc';
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT ?? process.env.NODE_ENV ?? 'development';
export const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
export const VIDEO_CONFERENCE_APP_BASE_PATH = process.env.VIDEO_CONFERENCE_APP_BASE_PATH ?? '/';
