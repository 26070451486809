import {
  Button,
  Error,
  ErrorToast,
  Icon,
  MainContent,
  TextInput,
  ToggleTip,
} from '@myhealthhub/design-system';
import {
  ConferenceErrorMessageProperties,
  ConferenceErrorType,
} from '@myhealthhub/video-conference-app-lib';
import React, { FormEvent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getVideoConference } from '../../api/videoConferenceRoute/videoConferenceRoute';
import { PageHeading } from '../../components';
import {
  CAPTCHA_DATA_SITE_KEY,
  HOSPITAL_NAME,
  LOCATION_PARAM,
  ROUTES,
} from '../../constants';
import { VideoPropertyContext } from '../../context/VideoPropertyContext';
import { useFocusOnRouteChange } from '../../hooks';
import '../../i18n';
import { TransLink } from '../../util/linkUtil';
import { isDisplayNameValid, isRoomNameValid } from '../../util/stringUtil';
import { ViewBase } from '../viewBase';
import UnsupportedBrowserToast from './unsupportedBrowserToast';
import './directory.css';

/* eslint max-lines: off */

const TOGGLE_TIP_POS = {
  SMALL: 'bottom-left',
  LARGE: 'right',
};

function ToggleTipIcon(): ReactElement {
  return (
    <Icon label="How do I get the location identifier?" size="1.35rem">
      <svg width="33" height="32" viewBox="0 0 33 32">
        <path
          fill="#007065"
          fillRule="evenodd"
          d={`M16.5 3c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zm0 2c-6.075 0-11 4.925-11 \
          11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm0 15.79c.756 0 1.368.612 1.368 1.368 0 .756-.612 \
          1.368-1.368 1.368-.756 0-1.368-.612-1.368-1.368 0-.756.612-1.369 1.368-1.369zm0-11.632c2.078 0 3.763 \
          1.685 3.763 3.763 0 1.683-1.11 3.16-2.666 3.639l-.07.019v1.816c0 .533-.408.972-.928 \
          1.021l-.099.005c-.533 0-.972-.407-1.022-.927l-.004-.1V16.12c0-.75.555-1.371 \
          1.277-1.474l.077-.008.07-.014c.714-.173 1.258-.826 \
          1.309-1.588l.004-.115c0-.945-.766-1.71-1.711-1.71-.907 0-1.649.705-1.707 1.598l-.004.112c0 .567-.459 \
          1.026-1.026 1.026s-1.026-.46-1.026-1.026c0-2.078 1.685-3.763 3.763-3.763z`}
        />
      </svg>
    </Icon>
  );
}

export function LocationId(): ReactElement {
  return (
    <span>
      <span aria-hidden="true"><Trans i18nKey="LOCATION_ID" /></span>
      <span className="visually-hidden"><Trans i18nKey="LOCATION_IDENTIFIER" /></span>
    </span>
  );
}

function PrivacyPolicyLink(): ReactElement {
  const translate = useTranslation().t;

  return (
    <TransLink
      href={translate('EXT_PRIVACY')}
      defaultText={translate('PRIVACY_POLICY')}
    />
  );
}

export function ConferenceErrorMessage(props: ConferenceErrorMessageProperties): ReactElement {
  const { errorType, roomName } = props;
  const translate = useTranslation().t;
  const locationIdField = <LocationId />;
  const errorMessage = {
    conferenceApiError: (
      <span>
        <Trans
          i18nKey="CANNOT_CONNECT_TO_LOCATION_ID_PLEASE_CHECK_THE_ID_AND_TRY_AGAIN"
          values={{ roomName}}
          components={{ locationId: locationIdField }}
        />
      </span>
    ),
    conferenceGenericError: (
      <span>
        {translate('PLEASE_TRY_AGAIN')}
      </span>
    ),
  };

  return (
    <div className="text-left">
      <p className="bold error-dark small">
        {translate('WE_COULD_NOT_CONNECT_YOUR_CALL_AT_THIS_TIME')}
      </p>
      <p className="xsmall">
        {errorMessage[errorType]}
        {' '}
        {translate('PLEASE_CONTACT_SUPPORT')}
      </p>
    </div>
  );
}

export default function Directory(): ReactElement {
  const history = useHistory();
  const { state, update: updateState } = useContext(VideoPropertyContext);
  const translate = useTranslation().t;
  const policyLink = <PrivacyPolicyLink />;
  const locationIdField = <LocationId />;
  const errorMessages = {
    captcha: <span>{translate('NOT_A_ROBOT')}</span>,
    displayName: <span>{translate('LONGER_NAME_REQUIRED')}</span>,
    roomName:
  <span>
    <Trans
      i18nKey="UNABLE_TO_CONNECT_TO_THE_PROVIDED_LOCATION_ID"
      components={{ locationId: locationIdField }}
    />
  </span>,
  };
  const [displayNameError, setDisplayNameError] = useState<JSX.Element>(null);
  const [roomNameError, setRoomNameError] = useState<JSX.Element>(null);
  const [captchaError, setCaptchaError] = useState<JSX.Element>(null);
  const [conferenceErrorType, setConferenceErrorType] = useState(
    ConferenceErrorType.conferenceGenericError,
  );
  const [submittedRoomName, setSubmittedRoomName] = useState('');
  const [showConferenceError, setShowConferenceError] = useState(false);
  const [callButtonDisabled, setCallButtonDisabled] = useState(false);
  const [toggleTipPos, setToggleTipPos] = useState(TOGGLE_TIP_POS.SMALL);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const locationId = new URLSearchParams(useLocation().search).get(LOCATION_PARAM);

  function errorFound(conferenceError: ConferenceError) {
    setShowConferenceError(true);
    setConferenceErrorType(conferenceError.errorType);
    if (conferenceError.errorType === ConferenceErrorType.conferenceApiError) {
      setSubmittedRoomName(conferenceError.roomName);
    }
  }

  function inputsValid(): boolean {
    const displayNameValid = isDisplayNameValid(state.displayName);
    const roomNameValid = isRoomNameValid(state.roomName);
    const captchaAnswer = captchaRef.current.getValue();

    if (!displayNameValid) {
      setDisplayNameError(errorMessages.displayName);
      return false;
    }
    if (!roomNameValid) {
      setRoomNameError(errorMessages.roomName);
      return false;
    }

    if (!captchaAnswer) {
      setCaptchaError(errorMessages.captcha);
      return false;
    }

    return true;
  }

  async function onSubmitForm(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    setShowConferenceError(false);
    setCallButtonDisabled(true);

    if (!inputsValid()) {
      setCallButtonDisabled(false);

      return;
    }

    try {
      const captchaAnswer = captchaRef.current.getValue();
      const data = await getVideoConference(
        state.displayName,
        state.roomName,
        captchaAnswer,
      );

      if (data === null) {
        errorFound({errorType: ConferenceErrorType.conferenceApiError, roomName: state.roomName});
        captchaRef.current.reset();
      } else {
        updateState({
          ...state,
          jwt: data.jwt,
          generatedRoomName: data.room,
        });
        localStorage.setItem('directoryDisplayName', state.displayName);
        history.push(ROUTES.PREVIEW);
      }
    } catch (error) {
      console.error(`Error while establishing call: ${error}`);

      errorFound({errorType: ConferenceErrorType.conferenceGenericError});
    }
    setCallButtonDisabled(false);
  }

  function handleBlurInput(
    isInputValid: () => boolean,
    setter: React.Dispatch<React.SetStateAction<JSX.Element>>,
    errorMessage: JSX.Element,
  ) {
    return () => {
      if (isInputValid()) {
        setter(null);
      } else {
        setter(errorMessage);
      }

      setCallButtonDisabled(false);
    };
  }

  function onChangeValue(event: Event) {
    if (event.currentTarget instanceof HTMLInputElement) {
      const evtName: string = event.currentTarget.name;
      const evtVal: string = event.currentTarget.value.trim();

      updateState({
        ...state,
        [evtName]: evtVal,
      });
    }
  }

  function setToggleTipPosByWidth() {
    if (window.innerWidth) {
      const newToggleTipPos = window.innerWidth > 1065
        ? TOGGLE_TIP_POS.LARGE
        : TOGGLE_TIP_POS.SMALL;

      setToggleTipPos(newToggleTipPos);
    }
  }

  function dismissError() {
    setShowConferenceError(false);
  }

  useEffect(() => {
    if (!state.roomName && locationId && isRoomNameValid(locationId)) {
      updateState({...state, roomName: locationId});
    }
  }, [locationId, state, updateState]);

  useEffect(() => {
    const resizeListener = () => {
      setToggleTipPosByWidth();
    };

    // Set position on initial render and update on window resize
    setToggleTipPosByWidth();
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  useFocusOnRouteChange(history.action);

  return (
    <ViewBase view="family" className="directory">
      <MainContent className="main pad-xlarge-top margin-center">
        <div className="content bg-neutral-offwhite round-medium pad-large tertiary">
          <ErrorToast
            active={showConferenceError}
            doDismiss={dismissError}
          >
            <ConferenceErrorMessage
              errorType={conferenceErrorType}
              roomName={submittedRoomName}
            />
          </ErrorToast>
          <PageHeading
            main={HOSPITAL_NAME}
            sub={translate('MYHEALTHHUB_CONNECTIONS')}
          />
          <UnsupportedBrowserToast />
          <form
            className="start-form margin-center margin-xlarge-bottom tertiary"
            autoComplete="off"
            onSubmit={onSubmitForm}
          >
            <div className="flex left top fields-wrapper">
              <div
                className="field-name"
                data-testid="displayNameOuter"
              >
                <TextInput
                  label={translate('YOUR_OWN_NAME')}
                  theme="tertiary"
                  name="displayName"
                  placeholder={translate('ENTER_YOUR_OWN_NAME')}
                  onChange={onChangeValue}
                  onBlur={handleBlurInput(
                    () => isDisplayNameValid(state.displayName),
                    setDisplayNameError,
                    errorMessages.displayName,
                  )}
                  defaultValue={state.displayName}
                  error={displayNameError}
                  required
                />
              </div>
              <div
                className="field-location flex left top"
                data-testid="roomNameOuter"
              >
                <TextInput
                  label={(
                    <span>
                      <LocationId />
                      {' '}
                      <span className="neutral-dark normal">
                        (
                        {translate('INCLUDE_HYPHENS')}
                        )
                      </span>
                    </span>
                  )}
                  theme="tertiary"
                  name="roomName"
                  placeholder={translate('LOCATION_PLACEHOLDER')}
                  onChange={onChangeValue}
                  onBlur={handleBlurInput(
                    () => isRoomNameValid(state.roomName),
                    setRoomNameError,
                    errorMessages.roomName,
                  )}
                  defaultValue={state.roomName}
                  error={roomNameError}
                  required
                />
                <ToggleTip
                  className="toggle-tip"
                  position={toggleTipPos}
                  trigger={<ToggleTipIcon />}
                  theme="tertiary"
                >
                  <Trans
                    i18nKey="YOU_CAN_GET_LOCATION_ID_INSTRUCTIONS"
                    components={{ locationId: locationIdField }}
                  />
                </ToggleTip>
              </div>
            </div>
            <div className="margin-medium-bottom">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={CAPTCHA_DATA_SITE_KEY}
                data-testid="captcha"
              />
              <Error>{captchaError}</Error>
            </div>
            <div className="flex left submit-row">
              <Button
                theme="tertiary"
                type="submit"
                size="small"
                disabled={callButtonDisabled}
              >
                {translate('START_CALL')}
              </Button>
              <p className="xsmall margin-none">
                <Trans
                  i18nKey="BY_USING_YOU_AGREE_TO_POLICY"
                  components={{ policyLink }}
                />
              </p>
            </div>
          </form>
        </div>
      </MainContent>
    </ViewBase>
  );
}

type ConferenceError = ConferenceApiError | ConferenceGenericError;

interface ConferenceApiError {
  errorType: ConferenceErrorType.conferenceApiError;
  roomName: string;
}

interface ConferenceGenericError {
  errorType: ConferenceErrorType.conferenceGenericError;
}
