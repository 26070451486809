import { manageFallbackFocus } from '@myhealthhub/design-system';
import { Action } from 'history';
import { useEffect } from 'react';

export const ACTION: Record<string, Action> = {
  PUSH: 'PUSH',
  POP: 'POP',
};

function useFocusOnRouteChange(
  routeAction?: string,
  locationHash?: string,
) {
  useEffect(() => {
    if (!routeAction) {
      return null;
    }
    const elementId = locationHash ? locationHash.replace('#', '') : null;

    if (routeAction === ACTION.PUSH || elementId) {
      manageFallbackFocus(elementId);
    }
  }, [routeAction, locationHash]);
}

export default useFocusOnRouteChange;
