import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { en_CA, fr_CA } from './locales/index';

const resources = {
  en: {
    translation: en_CA,
  },
  fr: {
    translation: fr_CA,
  },
};

void i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  nonExplicitSupportedLngs: true,
  fallbackLng: 'en',
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
  },
  detection: {
    order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain', 'cookie'],
  },
});

export default i18n;
