import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import '../../i18n';
import { BackLink } from '../BackLink';
import './BrandBar.css';

export const routesWithBackLink = [ROUTES.HELP];

function BrandBar(props: RouteComponentProps): ReactElement {
  const displayBackLink = routesWithBackLink.includes(props.location.pathname);
  const translate = useTranslation().t;

  return (
    <div className="brand-bar">
      <div className="brand-content flex pad-small-y margin-center">
        {displayBackLink && <BackLink />}
        <div className="brand-logo">
          <Link
            to={ROUTES.HOME}
            className="logo-link margin-center"
          >
            <img
              src={translate('MYHEALTHHUB_LOGO')}
              alt={translate('MYHEALTHHUB_CONNECTIONS_ALT_TEXT')}
              className="logo"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withRouter(BrandBar);
