import { VIDEO_ENGINE_ORIGIN } from '../constants';

export const loadVideoDisplayScript = (callback: { (): void; (): void }) => {
  const existingScript = document.getElementById('videoDisplayScript');

  if (!existingScript) {
    const script = document.createElement('script');

    script.src = `${VIDEO_ENGINE_ORIGIN}/external_api.js`;
    script.id = 'videoDisplayScript';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) {
        return callback();
      }

      return null;
    };
  }

  if (existingScript && callback) {
    return callback();
  }

  return null;
};
