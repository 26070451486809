import { Icon, palette } from '@myhealthhub/design-system';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import '../../i18n';
import './BackLink.css';

function BackLink(): ReactElement {
  const translate = useTranslation().t;

  return (
    <div className="back-link tertiary">
      <Link to={ROUTES.HOME} className="flex left">
        <Icon name="UpArrow" label="" size="1.5em" colour={palette.tertiary.dark} />
        <span className="visually-hidden">{translate('BACK_TO_HOME')}</span>
        <span className="link-text" aria-hidden="true">{translate('BACK')}</span>
      </Link>
    </div>
  );
}

export default BackLink;
