import { CaptureConsole } from '@sentry/integrations';
import { init as sentryInit } from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_RELEASE } from './constants';

sentryInit({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  release: SENTRY_RELEASE,
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
});

ReactDOM.render(<App />, document.getElementById('root'));
