import {
  Command,
  ConfigOptions,
  Properties,
  UiConfigOptions,
} from '@myhealthhub/video-conference-app-lib';
import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES, VIDEO_ENGINE_ORIGIN } from '../../constants';
import { VideoPropertyContext } from '../../context/VideoPropertyContext';
import '../../i18n';
import './VideoDisplay.css';

interface JitsiMeetApiOptions {
  roomName: string;
  userInfo: { displayName: string };
  parentNode: HTMLDivElement;
  interfaceConfigOverwrite: UiConfigOptions;
  jwt: Properties['jwt'];
  devices: Properties['devices'];
  noSSL: boolean;
  configOverwrite: ConfigOptions;
}

export const INTERFACE_CONFIG_DEFAULTS: UiConfigOptions = {
  DEFAULT_LOCAL_DISPLAY_NAME: 'me',
  DEFAULT_REMOTE_DISPLAY_NAME: 'A participant',
  DISABLE_FOCUS_INDICATOR: true,
  GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
  MOBILE_APP_PROMO: false,
  SETTINGS_SECTIONS: isMobile ? ['devices'] : ['devices', 'language'],
  SHOW_JITSI_WATERMARK: false,
  SHOW_WATERMARK_FOR_GUESTS: false,
  TOOLBAR_BUTTONS: ['fodeviceselection', 'settings'],
};

const mergeInterfaceOverwrites = (
  incomingUiConfig: UiConfigOptions,
  localDisplayName: string,
  remoteDisplayName: string,
): UiConfigOptions => {
  const toolbarButtonsSet = incomingUiConfig?.TOOLBAR_BUTTONS
    ? [
      ...new Set([
        ...incomingUiConfig.TOOLBAR_BUTTONS,
        ...INTERFACE_CONFIG_DEFAULTS.TOOLBAR_BUTTONS,
      ]),
    ]
    : INTERFACE_CONFIG_DEFAULTS.TOOLBAR_BUTTONS;

  const settingsSectionsSet = incomingUiConfig?.SETTINGS_SECTIONS
    ? [
      ...new Set([
        ...incomingUiConfig.SETTINGS_SECTIONS,
        ...INTERFACE_CONFIG_DEFAULTS.SETTINGS_SECTIONS,
      ]),
    ]
    : INTERFACE_CONFIG_DEFAULTS.SETTINGS_SECTIONS;

  return {
    ...incomingUiConfig,
    ...INTERFACE_CONFIG_DEFAULTS,
    DEFAULT_LOCAL_DISPLAY_NAME: localDisplayName,
    DEFAULT_REMOTE_DISPLAY_NAME: remoteDisplayName,
    TOOLBAR_BUTTONS: toolbarButtonsSet,
    SETTINGS_SECTIONS: settingsSectionsSet,
  };
};

function VideoDisplay(props: Properties): ReactElement {
  const { Config, UiConfig } = props;
  const translate = useTranslation().t;
  const translateRef = useRef(translate);
  const history = useHistory();
  const configRef = useRef(Config);
  const uiConfigRef = useRef(UiConfig);

  const ref = useRef<HTMLDivElement | null>(null);
  const { state: videoProperties, update: updateVideoProperties } = useContext(VideoPropertyContext);
  const videoPropertiesRef = useRef(videoProperties);
  const updateVideoPropertiesRef = useRef(updateVideoProperties);

  if (!VIDEO_ENGINE_ORIGIN) {
    console.error('Failed to start the VideoDisplay. VIDEO_ENGINE_ORIGIN is empty');
    history.push(ROUTES.ERROR);
  }

  useEffect(() => {
    const hostAsUrl = new URL(VIDEO_ENGINE_ORIGIN);
    const domain = hostAsUrl.hostname;
    const noSsl = hostAsUrl.protocol === 'http:';

    if (window.JitsiMeetExternalAPI) {
      try {
        const options: JitsiMeetApiOptions = {
          roomName: videoProperties.generatedRoomName,
          userInfo: {
            displayName: videoPropertiesRef.current.displayName,
          },
          parentNode: ref.current,
          interfaceConfigOverwrite: mergeInterfaceOverwrites(
            uiConfigRef.current,
            translateRef.current('ME'),
            translateRef.current('A_PARTICIPANT'),
          ),
          jwt: videoProperties.jwt,
          devices: videoPropertiesRef.current.devices,
          noSSL: noSsl,
          configOverwrite: configRef.current,
        };

        const api = new window.JitsiMeetExternalAPI(
          domain,
          options,
        );

        updateVideoPropertiesRef.current({
          ...videoPropertiesRef.current,
          api,
        });

        return () => {
          api.dispose();
        };
      } catch (error) {
        console.error('Failed to start the conference', error);
      }
    } else {
      console.error('Jitsi Meet API library not loaded. Did you include it in the html body?');
    }
  }, [videoProperties.jwt, videoProperties.generatedRoomName]);

  // Jitsi does not respect the displayName as passed
  // in with options.userInfo.displayName so we must
  // hack displayName as soon as the api is available
  useEffect(() => {
    if (videoProperties.api) {
      videoProperties.api.executeCommand(Command.displayName, videoProperties.displayName);
      videoProperties.api.executeCommand('subject', ' ');
    }
  }, [videoProperties]);

  return (
    <div className="video-display-container">
      <div
        className="video-display-inner"
        data-testid="videoDisplayTestId"
        ref={ref}
      />
    </div>
  );
}

export default VideoDisplay;
