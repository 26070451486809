import { GlobalStyle } from '@myhealthhub/design-system';
import React, { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ASSETS_PATH, ROUTES, VIDEO_CONFERENCE_APP_BASE_PATH } from './constants';
import { VideoPropertyProvider } from './context/VideoPropertyContext';
import { loadVideoDisplayScript } from './util/videoDisplayUtil';
import {
  CallEnd,
  ConferenceRoom,
  DevicePreview,
  Directory,
  ErrorNotFound,
  HelpCentre,
  Privacy,
  Terms,
} from './views';

export default function App(): ReactElement {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    loadVideoDisplayScript(() => {
      setIsScriptLoaded(true);
    });
  }, []);

  return (
    <>
      <GlobalStyle />
      {isScriptLoaded && (
        <BrowserRouter basename={VIDEO_CONFERENCE_APP_BASE_PATH}>
          <VideoPropertyProvider>
            <Switch>
              <Route
                exact
                path={ROUTES.HOME}
                component={Directory}
              />
              <Route
                path={ROUTES.PREVIEW}
                component={DevicePreview}
              />
              <Route
                path={ROUTES.CALL}
                component={ConferenceRoom}
              />
              <Redirect from={ASSETS_PATH} to={ROUTES.HOME} />
              <Route path={ROUTES.END} component={CallEnd} />
              <Route path={ROUTES.TERMS} component={Terms} />
              <Route path={ROUTES.PRIVACY} component={Privacy} />
              <Route path={ROUTES.HELP} component={HelpCentre} />
              <Route path={ROUTES.ERROR} component={ErrorNotFound} />
              <Route component={ErrorNotFound} />
            </Switch>
          </VideoPropertyProvider>
        </BrowserRouter>
      )}
    </>
  );
}
