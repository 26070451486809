import { ViewContainer } from '@myhealthhub/design-system';
import { Navigation } from '@myhealthhub/video-conference-app-lib';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { BrandBar, Footer } from '../../components';
import './viewBase.css';

function getViewClassName(view: string, className: string): string {
  let fullClassName = `${className || ''} app page-width page-height flex ${view}`;

  if (view === 'family') {
    fullClassName += ' bg-neutral-offwhite';
  }
  if (view === 'call') {
    fullClassName += ' bg-neutral-black';
  }

  return fullClassName;
}

function ColourBar(): ReactElement {
  return (
    <div
      className="colour-bar blend-multiply gradient-tertiaryX page-width"
      aria-hidden="true"
    />
  );
}

export interface Props {
  children?: ReactNode;
  navigation?: Navigation;
  view?: string;
  className?: string;
}

function ViewBase({ children, navigation, view, className }: Props): ReactElement {
  const isFamilyView = view === 'family';

  return (
    <ViewContainer
      className={getViewClassName(view, className)}
      data-testid="viewBaseTestId"
      plain
    >
      {isFamilyView && <BrandBar />}
      <div className="view page-width">
        {isFamilyView && <ColourBar />}
        {children}
      </div>
      {navigation
                && <Link to={`/${navigation.route}`}>{navigation.text}</Link>}
      {isFamilyView && <Footer />}
    </ViewContainer>
  );
}

export default ViewBase;
