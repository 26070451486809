import { MainContent } from '@myhealthhub/design-system';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useFocusOnRouteChange } from '../../hooks';
import '../../i18n';
import { ViewBase } from '../viewBase';
import ErrorIcon from './errorIcon';
import './errorNotFound.css';

function HomeLink(
  props: { children: string },
): ReactElement {
  return (
    <p className="margin-large-top">
      <Link
        to={ROUTES.HOME}
        className="button tertiary button-small outline"
        {...props}
      />
    </p>
  );
}

function AppErrorMessage(): ReactElement {
  const translate = useTranslation().t;
  return (
    <>
      <p>
        {translate('AUDIO_VIDEO_GENERAL_HELP_MESSAGE')}
      </p>
      <HomeLink>{translate('TRY_AGAIN')}</HomeLink>
    </>
  );
}

function PageNotFoundMessage(): ReactElement {
  const translate = useTranslation().t;
  return (
    <>
      <p>
        {translate('PAGE_NOT_AVAILABLE')}
      </p>
      <HomeLink>{translate('RETURN_TO_HOME')}</HomeLink>
    </>
  );
}

function ErrorNotFound(props: RouteComponentProps): ReactElement {
  const isError = props.location.pathname === ROUTES.ERROR;
  const translate = useTranslation().t;

  useFocusOnRouteChange(props.history.action);

  return (
    <ViewBase view="error">
      <div className="flex center text-center page-height tertiary">
        <MainContent className="error-view max-width-40 pad-medium">
          <ErrorIcon />
          <div className="margin-medium-top">
            <h1 className="title-small">{translate('SOMETHING_WENT_WRONG')}</h1>
            {isError ? <AppErrorMessage /> : <PageNotFoundMessage />}
          </div>
        </MainContent>
      </div>
    </ViewBase>
  );
}

export default withRouter(ErrorNotFound);
